<template>
  <div class="full-width">
    <v-app-bar
      app
      color="white"
      class="ma-2 mt-0 rounded-br-xl rounded-bl-xl pr-2 no-print"
      elevation="1"
    >
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h3 class="text-center">Contratar plano</h3>
        </v-col>
      </v-row>
      <div class="user-menu"><user-menu /></div>
    </v-app-bar>

    <div v-if="hasPlans" class="planos">
      <plan-card2
        v-for="plan in plans"
        :key="plan.id"
        :escolas="escolas"
        :plan="plan"
        @click="(schoolId) => selectPlan({ ...plan, schoolIds: [schoolId] })"
      />
    </div>
    <template v-else>
      <new-perseu-card>
        <empty-list text="Nenhum plano disponível para assinatura." />
      </new-perseu-card>
    </template>
  </div>
</template>

<script>
import { getPlansByRole } from "@/services/external/paypal-service";
import { getAll as getAllEscolas } from "@/services/external/escolas-service";

export default {
  name: "Plans",
  components: {
    PlanCard2: () =>
      import("@/domains/subscriptions/presentation/components/PlanCard2"),
    UserMenu: () => import("@/layouts/components/UserMenu"),
  },
  data: () => ({
    plans: [],
    subscriptionData: {
      validUntil: null,
    },
  }),
  computed: {
    hasSubscribedSomePlan() {
      return !!this.plans.find((plan) => plan.disableToSubscribe);
    },
    hasPlans() {
      return this.plans && this.plans.length > 0;
    },
  },
  created() {
    this.findTherapistsPlans();
    this.getEscolas();
  },
  methods: {
    async getEscolas() {
      try {
        const response = await getAllEscolas();

        this.escolas = response.data;
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async findTherapistsPlans() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getPlansByRole();
        this.plans = data;
        const plansDisabled = ["Básico", "Premium"];
        this.plans.forEach((plan) => {
          if (plansDisabled.includes(plan.name)) {
            // plan.disableToSubscribe = true;
            plan.unavailable = true;
          }
        });
      } catch (e) {
        this.$errorHandler(e, true);
        // this.$router.push({ name: "subscriptions.my" });
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    selectPlan(plan) {
      this.$store.commit("subscriptions/setSelectedPlan", plan);

      if (this.hasSubscribedSomePlan) {
        this.$router.push({ name: "subscriptions.changePlanConfirmation" });
      } else {
        this.$router.push({ name: "subscriptions.confirmation" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-icon-container {
  position: relative;
  background-color: #eaeaea;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.planos {
  display: flex;
  justify-content: center;
  gap: 20px;

  flex-wrap: wrap;
}
.planos > * {
  margin-top: 50px;
}

.user-menu {
  position: absolute;
  top: 5px;
  right: 10px;
}
</style>
